/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.2.0";
import "assets/demo/demo.css";

import Index from "views/Index.js";
import GamePage from "views/GamePage.js"
import AddPortalGame from "views/AddPortalGame.js";
import IndexNew from "views/IndexNew.js";
import RegisterPage from "views/examples/RegisterPage.js";
import LoginPage from "views/examples/LoginPage.js";
import ProfilePage from "views/examples/PublicAccountPage.js";
import AccountPage from "views/examples/AccountPage.js";
import EditAccountPage from "views/examples/EditAccountPage.js";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries





ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/components" render={(props) => <Index {...props}/>} />
      <Route
        path="/register"
        render={(props) => <RegisterPage {...props}/>}
      />
      <Route
        path="/login"
        render={(props) => <LoginPage {...props}/>}
      />
      <Route
        path="/profile/:id"
        render={(props) => <ProfilePage {...props}/>}
      />
      <Route
        path="/account"
        render={(props) => <AccountPage {...props}/>}
      />
      <Route
        path="/edit"
        render={(props) => <EditAccountPage {...props}/>}
      />
      <Route
        path="/add-game"
        render={(props) => <AddPortalGame {...props}/>}
      />
      <Route
        path="/game/:id"
        render={(props) => <GamePage {...props}/>}
        />
        <Route exact path="/" render={(props) => <Index {...props} />} />
      <Redirect from="/home" to="/" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
