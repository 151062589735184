/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import classnames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
import MapSelectionBlock from "views/IndexSections/MapSelectionBlock.js"
import {useFirestore, db, auth} from "FirebaseInstance.js"

import {
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Label,
  FormGroup,
  Form,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";
import {collection, doc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import Chevron from "assets/img/right-chevron.png"
export default function CreateGameBlock(props) {
var firestore = useFirestore();
  const [inputFocus, setInputFocus] = React.useState(false);
    const numberOfCards = 11;
    const [error, setError] = React.useState("");
    const [gameCode, setGameCode] = React.useState("");
    const [gameTitle, setGameTitle] = React.useState("");
    const [editorLink, setEditorLink] = React.useState("");
    const [creatorName, setCreatorName] = React.useState("");
    const [playerCount, setPlayerCount] = React.useState(0);
    const [gameMode, setGameMode] = React.useState(0);
    const [customLogicUsed, setCustomLogicUsed] = React.useState(false);
    const [customLogicEligibility, setCustomLogicEligibility] = React.useState(true);
    const [gameDescription, setGameDescription] = React.useState("");
    const [gameCodeValidated, setGameCodeValidated] = React.useState(false);
    const [games, setGames] = React.useState([]);
    const [thumbnails, setThumbnails] = React.useState([]);
    const [currentThumbnail, setCurrentThumbnail] = React.useState(null);
    const [currentThumbnailIndex, setCurrentThumbnailIndex] = React.useState(0);
    const [maps, setMaps] = React.useState([]);
    const history = useHistory();

    function validatePlayerCount(count){
      var intCount = parseInt(count);
      if (intCount > 128){
        setPlayerCount(128);
      }else if (intCount < 1){
        setPlayerCount(1);
      }else{
        setPlayerCount(intCount)
      }
    }


        useEffect(() => {
          firestore.getThumbnailOptions((result) => {
            setThumbnails(result);
            setCurrentThumbnail(result[0].url);
          });
        }, []);

    const updateParentMaps = (data) => {
      setMaps(data);
    }


    function submitGame(mapList){
      if (gameTitle && gameTitle != ""){
        if (gameDescription && gameDescription != ""){
          if (playerCount && playerCount >= 1){
            if (mapList.length != 0){
              var mapDocRefs = new Array();
              mapList.forEach((map, i) => {
                    var newDoc = doc(db, "maps", map);
                    mapDocRefs.push(newDoc);
              });
              var editorProvided = "";
              if (editorLink != ""){
                editorProvided = editorLink;
              }
              console.log(currentThumbnail + " thumb")
              var game = {
                title: gameTitle,
                portalCode: gameCode,
                playerCount: parseInt(playerCount),
                gameMode: gameMode,
                editorLink: editorProvided,
                favoriteCount: 0,
                customLogic: customLogicUsed,
                description: gameDescription,
                creatorName: creatorName,
                creatorId: auth.currentUser.uid,
                banner: currentThumbnail,
                maps: mapDocRefs,
                submittedDate: new Date(),
                favorites: new Array()
              }
              firestore.createGame(game, (doc) => {
                history.push("/game/" + gameCode);

              });
            }else{
              alert("You must select at least one map!");
            }
          }else{
            alert("Player Count must be between 1-128");
          }
        }else{
          alert("Enter a description to continue");
        }
      }else{
        alert("Enter a title to continue");
        //show error
      }

    }



function checkGameCode(code){
  setGameCode(code);
  if (code.length == 6){
    firestore.checkIfGameCodeSubmitted(code, (returned) => {
      if (returned){
        //game exists
        setGameCodeValidated(false);
        setError("Game Already Submitted!");
      }else{
        //free to continue
        setGameCodeValidated(true);
        setError("");
      }
    })
  }
}

function nextThumbnail(){
  if (currentThumbnailIndex < thumbnails.length - 1){
    setCurrentThumbnailIndex(currentThumbnailIndex + 1);
    setCurrentThumbnail(thumbnails[currentThumbnailIndex + 1].url);
  }else{
    setCurrentThumbnailIndex(0);
    setCurrentThumbnail(thumbnails[0].url);
  }
}

function lastThumbnail(){
  if (currentThumbnailIndex == 0){
    setCurrentThumbnailIndex(thumbnails.length - 1);
    setCurrentThumbnail(thumbnails[thumbnails.length - 1].url);
  }else{
    setCurrentThumbnailIndex(currentThumbnailIndex - 1);
    setCurrentThumbnail(thumbnails[currentThumbnailIndex - 1].url);
  }
}

function toggleSelection(map){


}

  return (
    <div className="section section-basic" id="basic-elements">
      <Container>
            <div id="inputs mt-5">
          <h3 class="modernista mt-5">Add a new Portal Game</h3>
          {error && (
          <h3 class="modernista text-danger">{error}</h3>
          )}
          {!gameCodeValidated && (
            <div>
            <p className="category">Portal Share Code</p>
              <FormGroup>
                <Input maxLength='6' defaultValue={gameCode} onChange={e => checkGameCode(e.target.value)}  placeholder="aabbcc" type="text" />
              </FormGroup>
              </div>
          )}
          {gameCodeValidated && thumbnails && (
            <div>
          <span className="category">Portal Share Code: <h6 className="modernista">{gameCode}</h6></span>
            <p className="category mt-5">Name</p>
              <FormGroup>
                <Input maxLength='50' defaultValue={gameTitle} onChange={e => setGameTitle(e.target.value)}  placeholder="My Awesome Game" type="text" />
              </FormGroup>
            <p className="category mt-5">Thumbnail</p>
            <Container style={{aspectRatio: 16/9, height: '40vmin', display: 'flex', justifyContent:'center', alignContent: 'center', alignItems: 'center', padding: '0', position:'relative', backgroundSize: 'cover'}}>
            <img src={currentThumbnail} style={{aspectRatio: 16/9, backgroundColor: "#000000", position: 'absolute', opacity:'0.4', height: '100%'}}/>
            <img style={{aspectRatio: 16/9,backgroundColor: "#000000", position: 'absolute', opacity:'0.4', height: '100%'}}/>
              <h2 className="mt-5 modernista" style={{color: "#ffffff", zIndex: '5'}}>{gameTitle}</h2>
              <img style={{position: 'absolute', height: '5vmin', width: '5vmin', right: 0}} src={Chevron} onClick={nextThumbnail} />
              <img className="rotate180" style={{position: 'absolute', height: '5vmin', width: '5vmin', left: 0}} src={Chevron} onClick={lastThumbnail} />
            </Container>
              <p className="category mt-5">Description</p>
                <FormGroup>
                  <Input maxLength='500' style={{minHeight: '100px'}} defaultValue={gameDescription} onChange={e => setGameDescription(e.target.value)}  placeholder="Tell us about your game! What are the rules, objectives, and scoring mechanics?" type="textarea" />
                </FormGroup>
                <p className="category mt-5">Creator Name (Optional)</p>
                  <FormGroup>
                    <Input defaultValue={creatorName} onChange={e => setCreatorName(e.target.value)}  placeholder="xXBF_PORTAL_PROXx" type="text" />
                  </FormGroup>
                <p className="category mt-5">Portal Editor Link (Optional)</p>
                  <FormGroup>
                    <Input defaultValue={editorLink} onChange={e => setEditorLink(e.target.value)}  placeholder="https://portal.battlefield.com/experience/rules?playgroundId=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" type="text" />
                  </FormGroup>
              <p className="category mt-5">Player Count</p>
                <FormGroup>
                  <Input maxLength='3' min='1' max='128' value={playerCount} onChange={e => validatePlayerCount(e.target.value)}  placeholder="128" type="number" />
                </FormGroup>
                <p className="category mt-5">Base Game Mode</p>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      defaultChecked
                      defaultValue="option1"
                      id="tdm_radio"
                      name="gameplayRadios"
                      type="radio"
                      onChange={(e) => {
                            setCustomLogicEligibility(true);
                            setGameMode(0);
                      }}
                    />
                    <span className="form-check-sign" />
                    Team Death Match
                  </Label>
                  <Label check className="ml-5">
                    <Input
                      defaultValue="option1"
                      id="ffa_radio"
                      name="gameplayRadios"
                      type="radio"
                      onChange={(e) => {
                            setCustomLogicEligibility(true);
                            setGameMode(1);
                      }}
                    />
                    <span className="form-check-sign" />
                    Free For All
                  </Label>
                  <Label check className="ml-5">
                    <Input
                      defaultValue="option1"
                      id="cqs_radio"
                      name="gameplayRadios"
                      type="radio"
                      onChange={(e) => {
                            setCustomLogicEligibility(false);
                            setGameMode(2);
                      }}
                    />
                    <span className="form-check-sign" />
                    Conquest (Large)
                  </Label>
                  <Label check className="ml-5">
                    <Input
                      defaultValue="option1"
                      id="cql_radio"
                      name="gameplayRadios"
                      type="radio"
                      onChange={(e) => {
                            setCustomLogicEligibility(false);
                            setGameMode(3);
                      }}
                    />
                    <span className="form-check-sign" />
                    Conquest (Small)
                  </Label>
                  <Label check className="ml-5">
                    <Input
                      defaultValue="option2"
                      id="rush_radio"
                      name="gameplayRadios"
                      type="radio"
                      onChange={(e) => {
                            setCustomLogicEligibility(false);
                            setGameMode(4);
                      }}
                    />
                    <span className="form-check-sign" />
                    Rush
                  </Label>
                </FormGroup>
                {customLogicEligibility && (
                  <div>
                  <p className="category mt-5">Custom Logic</p>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <Input
                        defaultValue="option2"
                        id="yes_custom"
                        name="customLogicRadios"
                        type="radio"
                        onChange={(e) => {
                          setCustomLogicUsed(true);
                        }}
                      />
                      <span className="form-check-sign" />
                      Yes
                    </Label>
                    <Label check className="ml-5">
                      <Input
                        defaultChecked
                        defaultValue="option2"
                        id="no_custom"
                        name="customLogicRadios"
                        type="radio"
                        onChange={(e) => {
                          setCustomLogicUsed(false);
                        }}
                      />
                      <span className="form-check-sign" />
                      No
                    </Label>

                  </FormGroup>
                  </div>
                )}

                <MapSelectionBlock updateParent={updateParentMaps} selectedMode={gameMode}/>

                <Button onClick={() => {submitGame(maps)}}>Create Game</Button>

              </div>
          )}
        </div>
        </Container>
    </div>
  );
}
