/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import classnames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
import {useFirestore, getCurrentUser, auth} from "FirebaseInstance.js"
import heart from "assets/img/heart.png";
import heartOutline from "assets/img/heart_outline.png";
import { useHistory } from "react-router-dom";

import {
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Label,
  FormGroup,
  Form,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";
import * as algoliasearch from 'algoliasearch';
export default function GamesBlock(props) {
var firestore = useFirestore();
const history = useHistory();
  const [inputFocus, setInputFocus] = React.useState(false);
    const numberOfCards = 11;
    const [searchString, setSearchString] = React.useState("");
    const [games, setGames] = React.useState([]);
    const [editorsChoice, setEditorsChoice] = React.useState([]);
    const [faves, setFaves] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    const [index, setIndex] = React.useState(null);
    const [sortType, setSortType] = React.useState(0);
    //0 is newest first, 1 is most favorites, 2 is most favorites in the last 24 hours

    console.log(games);

    const ALGOLIA_APP_ID = "RG3ZUTIEB7";
    const ALGOLIA_API_KEY = "8b2023a7c68f430cb052549e5a3142e1";

    function updateSearch(srch){
        var client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY, {protcol: 'https'});
        if (index == null)
          setIndex(client.initIndex("GameIndex"));
      setSearchString(srch);
      if (srch == ""){
        refreshBaseData();
      }
      if (index){
        index.search(
          srch,
        ).then(async(data) => {
          var returnedGames = new Array();
          for await (const hit of data.hits) {
            await firestore.getGameById(hit.portalCode, (result) => {
              returnedGames.push(result);
            console.log('pushed: ' + result);
            })
          };
        console.log('setgames: ' + returnedGames);
        setGames(returnedGames);
        })
      }
    }

    function refreshBaseData() {
      if (sortType == 0){
        //get recent
        firestore.getRecentGames((result) => setGames(result));
        document.getElementById("sortRecent").classList.remove('btn-simple');
        document.getElementById("sortTop").classList.add('btn-simple');
        document.getElementById("sortTrending").classList.add('btn-simple');
      }else if (sortType == 1){
        //get top
        firestore.getTopGames((result) => setGames(result));
        document.getElementById("sortRecent").classList.add('btn-simple');
        document.getElementById("sortTop").classList.remove('btn-simple');
        document.getElementById("sortTrending").classList.add('btn-simple');
      }else if (sortType == 2){
        //get top of 36 hours
        firestore.getTrendingGames((result) => setGames(result));
        document.getElementById("sortRecent").classList.add('btn-simple');
        document.getElementById("sortTop").classList.add('btn-simple');
        document.getElementById("sortTrending").classList.remove('btn-simple');
      }
        firestore.getEditorsChoiceGames((result) => setEditorsChoice(result));
    }

    useEffect(() => {
      if (searchString == ""){
        refreshBaseData();
      }
          setRefresh(false);


    }, [refresh, sortType]);

    function favoriteGame(item){
      firestore.favoriteGame(item.portalCode, auth.currentUser.uid, (obj) => {
            setRefresh(true);
      })
    }
    function unFavoriteGame(item){
      firestore.unFavoriteGame(item.portalCode, auth.currentUser.uid, (obj) => {
          setRefresh(true);
      })
    }

    function loadGame(game){
        history.push("/game/" + game.portalCode);
    }

    function convertGameModeEnumToString(type){
      if (type == 0){
        return "Team Death Match";
      }else if (type == 1){
        return "Free For All";
      }else if (type == 2){
          return "Conquest Large";
      }else if (type == 3){
            return "Conquest Small";
      }else if (type == 4){
              return "Rush";
      }
    }

  return (
    <div className="section section-basic" id="games">
        <Container id="editorsChoice">
        <span><h1 className="modernista">Editor's Choice
          <i style={{marginLeft: '16px'}} className="fa fa-star" /></h1></span>
              <Row>
              {editorsChoice && editorsChoice.map((item) => {
                if (item != null){
                  return (
                    <Col xs={12} md={6} lg={4} key={item.portalCode}>
                        <Card style={{borderColor: "#FF6C00", backgroundColor:"#000000", borderWidth: '2px', borderStyle: 'solid'}}>
                        <CardImg top width="100%" src={item.banner} style={{opacity: '0.4'}} alt="Card image cap"/>
                        <div style={{position: 'absolute', display: 'flex', alignItems: 'center', alignContent: 'center', right: '16px', top: '16px'}}>
                        <div style={{marginRight: '16px'}}>
                        {item.favorites.length == 0 || item.favorites.length > 1 && (
                          <CardText tag="h4" className="modernista" style={{color: "#FF6C00"}}>{item.favorites.length}</CardText>
                        )}
                        {item.favorites.length == 1 && (
                          <CardText  tag="h4" className="modernista" style={{color: "#FF6C00"}}>{item.favorites.length}</CardText>
                        )}
                        {!item.favorites && (
                            <CardText tag="h4" className="modernista" style={{color: "#FF6C00"}}>0</CardText>
                        )}
                        </div>

                        {item.favorites && item.favorites.includes(auth.currentUser.uid) && (
                          <img onClick={() => unFavoriteGame(item)} style={{height: '30px', width: '30px'}} src={heart}/>
                        )}
                        {!item.favorites.includes(auth.currentUser.uid) && (
                          <img onClick={() => favoriteGame(item)} style={{height: '30px', width: '30px'}} src={heartOutline}/>
                        )}

                        </div>
                              <CardBody style={{backgroundColor:"#222a42"}}>
                                  <CardTitle className="modernista" tag="h5">{convertGameModeEnumToString(item.gameMode)}</CardTitle>
                                  <CardSubtitle tag="h6" className="mb-2 text-muted">{item.playerCount} Players</CardSubtitle>
                                  <CardTitle tag="h4">{item.title}</CardTitle>
                                  {item.description && item.description.length > 100 && (

                                    <CardText>{item.description.substring(0, 99)}...</CardText>
                                  )}
                                  {item.description && item.description.length < 100 && (

                                    <CardText>{item.description}</CardText>
                                  )}
                              </CardBody>
                              <CardBody style={{backgroundColor:"#1d253b"}}>


                              <div style={{display: 'flex', justifyContent: 'center'}}>
                              <Button onClick={() => {

                                 /* Copy the text inside the text field */
                                navigator.clipboard.writeText(item.portalCode.toUpperCase());

                                /* Alert the copied text */
                                alert("Copied Portal Code: " + item.portalCode.toUpperCase());
                              }}>{item.portalCode.toUpperCase()}</Button>
                              <Button  onClick={() => loadGame(item)}>Details</Button>
                              </div>
                              </CardBody>
                        </Card>
                    </Col>
                  )
                }
              })}
              </Row>
          </Container>
          <Container className="mt-5" style={{marginBottom: '24px',}}>

          <Row>
          <Col>
          <h1 className="modernista">All Games</h1>
          </Col>
          <Col style={{display: 'flex', paddingBottom: '16px', justifyContent: 'right'}}>

          <Button
          id="sortRecent"
          onClick={() => setSortType(0)}
          className="btn-round" color="primary" type="button">
            Recent
          </Button>
          <Button
            id="sortTop"
            className="btn-round btn-simple "
            color="primary"
            type="button"
            onClick={() => setSortType(1)}
          >
            Top (All Time)
          </Button>
          <Button
            id="sortTrending"
            className="btn-round btn-simple "
            color="primary"
            type="button"
            onClick={() => setSortType(2)}
          >
            Trending
          </Button>
          </Col>
          </Row>
          <input placeholder="Search Games" type="text" class="form-control" onChange={e => updateSearch(e.target.value)} value={searchString}/>
          </Container>
      <Container>
            <Row>
            {games && games.map((item) => {
              if (item != null){
                return (
                  <Col className="mb-5" xs={12} md={6} lg={4} key={item.portalCode}>
                      <Card style={{backgroundColor: "#000000"}}>
                            <CardImg top width="100%" src={item.banner} style={{opacity: '0.4'}} alt="Card image cap"/>
                            <div style={{position: 'absolute', display: 'flex', alignItems: 'center', alignContent: 'center', right: '16px', top: '16px'}}>
                            <div style={{marginRight: '16px'}}>
                            {item.favorites.length == 0 || item.favorites.length > 1 && (
                              <CardText tag="h4" className="modernista" style={{color: "#FF6C00"}}>{item.favorites.length}</CardText>
                            )}
                            {item.favorites.length == 1 && (
                              <CardText  tag="h4" className="modernista" style={{color: "#FF6C00"}}>{item.favorites.length}</CardText>
                            )}
                            {!item.favorites && (
                                <CardText tag="h4" className="modernista" style={{color: "#FF6C00"}}>0</CardText>
                            )}
                            </div>

                            {item.favorites && item.favorites.includes(auth.currentUser.uid) && (
                              <img onClick={() => unFavoriteGame(item)} style={{height: '30px', width: '30px'}} src={heart}/>
                            )}
                            {!item.favorites.includes(auth.currentUser.uid) && (
                              <img onClick={() => favoriteGame(item)} style={{height: '30px', width: '30px'}} src={heartOutline}/>
                            )}

                            </div>
                          <CardBody style={{backgroundColor:"#222a42"}}>
                              <CardTitle className="modernista" tag="h5">{convertGameModeEnumToString(item.gameMode)}</CardTitle>
                              <CardSubtitle tag="h6" className="mb-2 text-muted">{item.playerCount} Players</CardSubtitle>
                              <CardTitle tag="h4">{item.title}</CardTitle>
                              {item.description && item.description.length > 100 && (

                                <CardText>{item.description.substring(0, 99)}...</CardText>
                              )}
                              {item.description && item.description.length < 100 && (

                                <CardText>{item.description}</CardText>
                              )}
                          </CardBody>
                          <CardBody style={{backgroundColor:"#1d253b"}}>


                          <div style={{display: 'flex', justifyContent: 'center'}}>
                          <Button onClick={() => {

                             /* Copy the text inside the text field */
                            navigator.clipboard.writeText(item.portalCode.toUpperCase());

                            /* Alert the copied text */
                            alert("Copied Portal Code: " + item.portalCode.toUpperCase());
                          }}>{item.portalCode.toUpperCase()}</Button>
                          <Button  onClick={() => loadGame(item)}>Details</Button>
                          </div>
                          </CardBody>
                      </Card>
                  </Col>
                )
              }
            })}
            </Row>
        </Container>
    </div>
  );
}
