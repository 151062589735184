/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import { Link, useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import ogImage from "assets/img/og_image.jpg";
import {useFirestore, getCurrentUser, auth} from "FirebaseInstance.js";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, linkWithCredential, EmailAuthProvider  } from "firebase/auth";


export default function LoginPage() {
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);


  const firestore = useFirestore();

  const [displayName, setDisplayName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);

  const history = useHistory();

  function validateLogin(){
    if (email != "" && email.includes(".") && email.includes("@")){
      if (password != ""){
        signInWithEmailAndPassword(getAuth(), email, password)
          .then((usercred) => {
            const user = usercred.user;
              history.push("/");
            console.log("Account linking success", user);
          }).catch((error) => {
            console.log("Account linking error", error);
            alert("Error logging in. Did you mean to create an account?")
          });
      }else{
        alert("Password cannot be empty!");
      }
    }else{
      alert("Email cannot be empty, and must be a valid email address!")
    }
  }

  const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          //validateLogin();
          // callMyFunction();
        }
      };

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      onAuthStateChanged(auth, (user) => {
        if (user){
              if (!auth.currentUser.isAnonymous){
                history.push("/");
              }
        }else{

        }
      })
    // Specify how to clean up after this effect:
          if (auth.currentUser != null && !auth.currentUser.isAnonymous){
            history.push("/");
          }
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  },[]);
  return (
    <>
      <IndexNavbar />
      <Helmet>
          <title>{"BF Portal Pro"}</title>
          <meta name="title" content={"BF Portal Pro"} />
          <meta name="og:title" content={"BF Portal Pro"} />
          <meta name="twitter:title" content={"BF Portal Pro"} />
          <meta name="description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:description" content={"Play hundreds of new game modes on BF Portal Pro today!!"} />
          <meta name="twitter:description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:image" content={ogImage} />
          <meta name="twitter:image" content={ogImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bfportalpro" />
          <meta name="twitter:creator" content="@bfportalpro" />
      </Helmet>
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <Card className="card-register" style={{backgroundColor: "#000111"}}>
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png").default}
                      />
                      <CardTitle style={{color: "#ffffff"}} tag="h4">Login</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": passwordFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                          />
                        </InputGroup>
                    </Form>
                    </CardBody>
                    <CardFooter>
                      <Button onClick={validateLogin} className="btn-round" color="primary" size="lg">
                        Login
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>

            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
