/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import {useFirestore} from "FirebaseInstance.js"
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { HashLink as Link } from 'react-router-hash-link';
// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import Test from 'assets/img/animated_glitch_logo.gif';

export default function PageHeader() {
var firestore = useFirestore();
  const db = getFirestore();
    const [twitchStreaming, setTwitchStreaming] = useState(null);


    const unsub = onSnapshot(doc(db, "streaming", "twitch"), (doc) => {
      if (doc.data().liveNow){
          setTwitchStreaming(doc.data());
      }else{
          setTwitchStreaming(null);
      }
    });

    useEffect(() => {

    }, []);




  return (
    <div className="page-header header-filter">
    {twitchStreaming && (
      <div style={{height: '196px', width:'100%', position:'absolute', display: 'flex', justifyContent:'center', backgroundColor: "#ff0000"}}>
      <div style={{position: 'absolute', bottom: '16px', display: 'flex', justifyContent:'center'}}>
      <span style={{fontSize: '36px'}}><i style={{marginRight: '16px'}} className={twitchStreaming.fontAwesomeIcon} />STREAMING LIVE ON TWITCH NOW!</span>
      <Button style={{zIndex: 10}} className="ml-5" onClick={() => window.open(twitchStreaming.url, "_blank")}>Tune In</Button>
      </div>
      </div>
    )}
      <Container>
        <div className="content-center brand">
          <img src={Test}/>
          <h2>Find new game modes to play in BF2042 Portal!</h2>
          <Link to="/#games" smooth={true}>
          <Button
            className="btn-simple btn-round"
            color="primary"
            type="button"
          >
            Discover Games
          </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
}
