import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, signOut, onAuthStateChanged} from "firebase/auth";

import { getFirestore, limit, arrayUnion, orderBy, arrayRemove, collection, doc, setDoc, getDoc, getDocs, query, where, updateDoc, addDoc, onSnapshot, serverTimestamp } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {useState} from "react";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAtAx1HPNkp2r3HQJh5WdIgBP1gBDzmzU",
  authDomain: "bf-portal-pro.firebaseapp.com",
  projectId: "bf-portal-pro",
  storageBucket: "bf-portal-pro.appspot.com",
  messagingSenderId: "916974171629",
  appId: "1:916974171629:web:07a97960998b3e5d1263e4",
  measurementId: "G-22D31ESLE6"
};

initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user){
    //we're alive!
  }else{
    //make us live!
      signInAnonymously(auth).then(() => {
      	}, (error) => {
      		console.log(error);
      		return null;
      	});
  }
})

export const getCurrentUser = async () => {
  return await auth.currentUser;
}

export const getTrendingGamesForTimePeriod = async (hours) => {
  const q = query(collection(db, "games"), where("portal_code", "!=", "aabbccddeeff"));
  var docs = await getDocs(q);
  return docs;
}

export const useFirestore = () => {

  const createMap = async (map, onUpdate) => {
    var newDoc = doc(collection(db, "maps"));
    map.mapId = newDoc.id;
    await setDoc(newDoc, map);
    onUpdate(newDoc);
  }

  const updateDisplayName = async (displayName, onUpdate) => {
    var userDoc = doc(db, "users", auth.currentUser.uid);

    await updateDoc(userDoc, {
      displayName: displayName
    })

    onUpdate(userDoc);
  }

  const changeMyShowcasedAward = async (award, onUpdate) => {
    var newDoc = doc(db, "users", auth.currentUser.uid);

    await updateDoc(newDoc, {
      highlightedAward: award
    });

    onUpdate(newDoc);
  }

  const favoriteGame = async (gameId, userId, onUpdate) => {
    const gameRef = doc(db, "games", gameId);

    // Atomically add a new region to the "regions" array field.
    await updateDoc(gameRef, {
        favorites: arrayUnion(userId)
    });

    onUpdate("");
  }

  const unFavoriteGame = async (gameId, userId, onUpdate) => {

        const gameRef = doc(db, "games", gameId);
    // Atomically remove a region from the "regions" array field.
    await updateDoc(gameRef, {
        favorites: arrayRemove(userId)
    });

        onUpdate("");
  }

  const createGame = async (game, onUpdate) => {
    var newDoc = doc(db, "games", game.portalCode);
    await setDoc(newDoc, game);
    onUpdate(newDoc);
  }

  const setupUserInDatabase = async (display, email, userid, onUpdate) => {
    var newDoc = doc(db, "users", userid);
    const newcomerAward = {
      dateAwarded: new Date(),
      description: "Thanks for joining BF Portal Pro!",
      icon: "https://firebasestorage.googleapis.com/v0/b/bf-portal-pro.appspot.com/o/awards%2Fnewcomer.png?alt=media",
      name: "Newcomer",
      proPoints: 100
    }
    await setDoc(newDoc, {
      displayName: display,
      email: email,
      userId: userid,
      games: [],
      favorites: [],
      proPoints: 100,
      awards: [newcomerAward],
      highlightedAward: newcomerAward,
      admin: false,
      avatar: "https://firebasestorage.googleapis.com/v0/b/bf-portal-pro.appspot.com/o/avatars%2Fdefault_avatar.png?alt=media",
      createdDate: serverTimestamp(),
    });
    onUpdate(newDoc);
  }

  const checkIfGameCodeSubmitted = async (gamecode, onUpdate) => {
    const docRef = doc(db, "games", gamecode);
    const docSnap = await getDoc(docRef);

    onUpdate(docSnap.exists());
  }

  const checkIfStreamingOnTwitch = async (onUpdate) => {
    const docRef = doc(db, "streaming", "twitch");
    const docSnap = await getDoc(docRef);

    onUpdate(docSnap.data());
  }

  const getGameById = async (gamecode, onUpdate) => {
    const docRef = doc(db, "games", gamecode);
    const docSnap = await getDoc(docRef);

    onUpdate(docSnap.data());
  }

  const getMapFromDocument = async (doc, onUpdate) => {
    const docSnap = await getDoc(doc);
    onUpdate(docSnap.data());
  }

  const getMapsForSearch = async (searchString, onUpdate) => {
    const response = []
    const citiesRef = collection(db, "maps");
    // Create a query against the collection.
    const q = query(citiesRef, where("title", ">=", searchString), where("title", "<=", searchString+ '\uf8ff'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  const getEditorsChoiceGames = async (onUpdate) => {
    const response = []
    const citiesRef = collection(db, "games");
    // Create a query against the collection.
    const q = query(citiesRef, where("editorsChoice", "==", true), orderBy("favoriteCount", "desc"), limit(3));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  const getUserDataByDisplayName = async (displayName, onUpdate) => {
    const response = new Array();
    const citiesRef = collection(db, "users");
    // Create a query against the collection.
    const q = query(citiesRef, where("displayName", "==", displayName), limit(1));
    const querySnapshot = await getDocs(q);
    console.log('got docs');
    var flag = null;
    querySnapshot.forEach((doc) => {
      console.log('pushed doc');
      flag = doc.data();
    });

    onUpdate(flag);
  }

  const getGamesByCreatorId = async (creatorId, onUpdate) => {
    const response = new Array();
    const citiesRef = collection(db, "games");
    // Create a query against the collection.
    const q = query(citiesRef, where("creatorId", "==", creatorId), orderBy("submittedDate", "desc"));
    const querySnapshot = await getDocs(q);
    console.log('got docs');
    querySnapshot.forEach((doc) => {
      console.log('pushed doc');
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  const getGamesForSearch = async (searchString, onUpdate) => {
    const response = []
    const citiesRef = collection(db, "games");
    // Create a query against the collection.
    const q = query(citiesRef, where("title", ">=", searchString), where("title", "<=", searchString+ '\uf8ff'), limit(50));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  const getRecentGames = async (onUpdate) => {
    const response = []
    const citiesRef = collection(db, "games");
    // Create a query against the collection.
    const q = query(citiesRef, orderBy("submittedDate", "desc"), limit(50));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  const checkIfDisplayNameAvailable = async (name, onUpdate) => {
    const response = []
    const usersRef = collection(db, "users");
    // Create a query against the collection.
    const q = query(usersRef, where("displayName", "==", name), limit(1));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response.length == 0);
  }

  const getTopGames = async (onUpdate) => {
    const response = []
    const citiesRef = collection(db, "games");
    // Create a query against the collection.
    const q = query(citiesRef, orderBy("favoriteCount", "desc"), orderBy("submittedDate", "desc"), limit(50));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  const getTrendingGames = async (onUpdate) => {
    const response = []
    const citiesRef = collection(db, "games");
    // Create a query against the collection.
    var now = new Date().getTime();
    var cutoffDate = new Date(now - (1000 * 60 * 60 * 36));
    const q = query(citiesRef, orderBy("submittedDate", "desc"), orderBy("favoriteCount", "desc"), where("submittedDate", ">=", cutoffDate), limit(50));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response.sort(compare));
  }

  const getMyUserData = async (onUpdate) => {
    const userRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()){
      onUpdate(docSnap.data());
    }else{
      onUpdate(null);
    }
  }

  const getThumbnailOptions = async (onUpdate) => {
    const response = []
    const citiesRef = collection(db, "thumbnailOptions");
    // Create a query against the collection.
    const q = query(citiesRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      response.push(doc.data());
    });
    onUpdate(response);
  }

  function compare( a, b ) {
  if ( a.favoriteCount < b.favoriteCount ){
    return 1;
  }
  if ( a.favoriteCount > b.favoriteCount ){
    return -1;
  }
  return 0;
}


return {getGamesForSearch, checkIfGameCodeSubmitted, createMap, getMapsForSearch, createGame, favoriteGame,
  checkIfStreamingOnTwitch, getThumbnailOptions, getRecentGames, getTopGames, getTrendingGames, checkIfDisplayNameAvailable,
  setupUserInDatabase, getMyUserData, getGamesByCreatorId, changeMyShowcasedAward, updateDisplayName, getUserDataByDisplayName,
   unFavoriteGame, getGameById, getMapFromDocument, getEditorsChoiceGames}

}
