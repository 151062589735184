/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {Helmet} from "react-helmet";
import ogImage from "assets/img/og_image.jpg";
import { Link, useHistory } from "react-router-dom";
import {useFirestore, getCurrentUser, auth} from "FirebaseInstance.js";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, linkWithCredential, EmailAuthProvider  } from "firebase/auth";


export default function RegisterPage() {
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);


  const firestore = useFirestore();

  const [displayName, setDisplayName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);

  function updateDisplayName(value){
    setDisplayName(value.replace(/\s/g, ''));
  }

  const history = useHistory();

  function validateRegistration(){
    if (email != "" && email.includes(".") && email.includes("@")){
      if (password != ""){
        if (displayName != ""){
          if (agreedToTerms){
            firestore.checkIfDisplayNameAvailable(displayName, (result) => {
              if (result == true){
                //lets go ahead and create their account from anonymous.
                var credential = EmailAuthProvider.credential(email, password);
                linkWithCredential(auth.currentUser, credential)
                  .then((usercred) => {
                    const user = usercred.user;
                      firestore.setupUserInDatabase(displayName, email, auth.currentUser.uid, (result) => {
                        history.push("/");
                      })
                    console.log("Account linking success", user);
                  }).catch((error) => {
                    console.log("Account linking error", error);
                    alert("Error creating account. Did you mean to login?")
                  });

              }else{
                alert('Display name is unavailable! Please try another.')
              }
            })
          }else{
            alert("You must agree to the terms and conditions to use BFPortal.Pro")
          }

        }else{
          alert("Display name cannot be empty!")
        }
      }else{
        alert("Password cannot be empty!");
      }
    }else{
      alert("Email cannot be empty, and must be a valid email address!")
    }
  }

  React.useEffect(() => {
    document.body.classList.toggle("register-page");

      onAuthStateChanged(auth, (user) => {
        if (user){
              if (!auth.currentUser.isAnonymous){
                history.push("/");
              }
        }else{

        }
      })

    if (!auth.currentUser.isAnonymous){
      history.push("/");
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  },[]);
  return (
    <>
      <IndexNavbar />
      <Helmet>
          <title>{"BF Portal Pro"}</title>
          <meta name="title" content={"BF Portal Pro"} />
          <meta name="og:title" content={"BF Portal Pro"} />
          <meta name="twitter:title" content={"BF Portal Pro"} />
          <meta name="description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:description" content={"Play hundreds of new game modes on BF Portal Pro today!!"} />
          <meta name="twitter:description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:image" content={ogImage} />
          <meta name="twitter:image" content={ogImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bfportalpro" />
          <meta name="twitter:creator" content="@bfportalpro" />
      </Helmet>
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <Card className="card-register" style={{backgroundColor: "#000111"}}>
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png").default}
                      />
                      <CardTitle style={{color: "#ffffff"}} tag="h4">Register</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": fullNameFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Display Name"
                            type="text"
                            maxLength={32}
                            value={displayName}
                            onChange={(e) => updateDisplayName(e.target.value)}
                            onFocus={(e) => setFullNameFocus(true)}
                            onBlur={(e) => setFullNameFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": passwordFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                          />
                        </InputGroup>
                        <FormGroup check className="text-left">
                          <Label check>
                            <Input onChange={(e) => setAgreedToTerms(e.target.checked)} type="checkbox" />
                            <span className="form-check-sign" />I agree to the{" "}
                            <Link to="/terms-and-conditions">
                            <a
                              onClick={(e) => e.preventDefault()}
                            >
                              terms and conditions
                            </a>
                            </Link>
                            .
                          </Label>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button onClick={validateRegistration} className="btn-round" color="primary" size="lg">
                        Get Started
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>

            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
