/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import {auth} from "FirebaseInstance.js"
import {onAuthStateChanged} from "firebase/auth";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function IndexNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [user, setUser] = React.useState(null);
  const [color, setColor] = React.useState("navbar-transparent");
  const [profileColor, setProfileColor] = React.useState("primary");
  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);

      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          setUser(user);
          // ...
        } else {
          // User is signed out
          // ...
        }
      });
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  },[]);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
      setProfileColor("default")
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setProfileColor("primary")
      setColor("navbar-transparent");
    }
  };

  const anonymousAuth = () => {

  }
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <span class="modernista">BF Portal Pro</span>
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Created by ALKY Plays
          </UncontrolledTooltip>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  BF Portal Pro
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar style={{alignItems: 'center', alignContent: 'center'}}>
          <NavItem className="p-0">
            <NavLink
              data-placement="bottom"
              href="https://discord.gg/gqRhNZZXm4"
              rel="noopener noreferrer"
              target="_blank"
              title="Join our Discord!"
            >
              <i className="fab fa-discord" />
              <p className="d-lg-none d-xl-none">Discord</p>
            </NavLink>
          </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://twitter.com/bfportalpro"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fab fa-twitter" />
                <p className="d-lg-none d-xl-none">Twitter</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.twitch.tv/bfportalpro"
                rel="noopener noreferrer"
                target="_blank"
                title="Watch on Twitch"
              >
                <i className="fab fa-twitch" />
                <p className="d-lg-none d-xl-none">Twitch</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.youtube.com/channel/UC7YDgGfBOAnhvxcM7nWVjvw"
                rel="noopener noreferrer"
                target="_blank"
                title="Subscribe on YouTube"
              >
                <i className="fab fa-youtube" />
                <p className="d-lg-none d-xl-none">YouTube</p>
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                href="#pablo"
                nav
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-cogs d-lg-none d-xl-none" />
                Quick Navigation
              </DropdownToggle>
              <DropdownMenu className="dropdown-with-icons">
              <DropdownItem tag={Link} to="/">
                <i className="fa fa-home" />
                Home
              </DropdownItem>
              <DropdownItem tag={Link} to="/add-game">
                <i className="fa fa-plus" />
                Add Portal Game
              </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
              <NavItem>
              <Link to="/add-game">
                <Button
                  className="nav-link d-lg-block"
                  color="default"
                >
                  <i className="fa fa-plus" /> Add Portal Game
                </Button>
                </Link>
              </NavItem>
              <NavItem >
              {user && user.isAnonymous && (
                <div>
            <Link to="/register">
                <Button
                  className="btn-simple btn-round btn-sm"
                  color={profileColor}
                  type="button"
                >
                    <i className="fa fa-user-circle" /> Register
                  </Button>
                  </Link>
                  <Link to="/login">
                      <Button
                        className="btn-simple btn-round btn-sm"
                        color="default"
                        type="button"
                        style={{color: "#ffffff"}}
                      >
                          Login
                        </Button>
                        </Link>
                  </div>
              )}
              {user && !user.isAnonymous && (

                <Link to="/account">
                <Button
                  className="btn-simple btn-round btn-sm"
                  color={profileColor}
                  type="button"
                >
                    <i className="fa fa-profile" /> Profile
                  </Button>
                  </Link>
              )}
              </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
