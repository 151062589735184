/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import classnames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
import {useFirestore, getCurrentUser, auth} from "FirebaseInstance.js"
import heart from "assets/img/heart.png";
import heartOutline from "assets/img/heart_outline.png";
import { useHistory, Link } from "react-router-dom";

import {
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Label,
  FormGroup,
  Form,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";
import * as algoliasearch from 'algoliasearch';
export default function GamesBlock(props) {
var firestore = useFirestore();
const history = useHistory();
  const [inputFocus, setInputFocus] = React.useState(false);
    const numberOfCards = 11;
    const [searchString, setSearchString] = React.useState("");
    const [games, setGames] = React.useState([]);
    const [editorsChoice, setEditorsChoice] = React.useState([]);
    const [faves, setFaves] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    const [index, setIndex] = React.useState(null);
    const [sortType, setSortType] = React.useState(0);
    const [noGames, setNoGames] = React.useState(false);
    //0 is newest first, 1 is most favorites, 2 is most favorites in the last 24 hours

    const ALGOLIA_APP_ID = "RG3ZUTIEB7";
    const ALGOLIA_API_KEY = "8b2023a7c68f430cb052549e5a3142e1";

    function updateSearch(srch){
        var client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY, {protcol: 'https'});
        if (index == null)
          setIndex(client.initIndex("GameIndex"));
      setSearchString(srch);
      if (srch == ""){
        refreshBaseData();
      }
      if (index){
        index.search(
          srch,
        ).then(async(data) => {
          var returnedGames = new Array();
          for await (const hit of data.hits) {
            await firestore.getGameById(hit.portalCode, (result) => {
              returnedGames.push(result);
            console.log('pushed: ' + result);
            })
          };
        console.log('setgames: ' + returnedGames);
        setGames(returnedGames);
        })
      }
    }

    function refreshBaseData() {
      console.log('refresh data requested');
      if (props.userData){
        firestore.getGamesByCreatorId(props.userData.userId, (result) => {
          console.log('getGames back ' + result);
          if (result.length == 0){
            console.log('testing');
            setNoGames(true);
          }
          setGames(result)});
      }
    }

    useEffect(() => {
      if (searchString == ""){
        refreshBaseData();
      }
          setRefresh(false);


    }, [refresh]);

    function favoriteGame(item){
      firestore.favoriteGame(item.portalCode, auth.currentUser.uid, (obj) => {
            setRefresh(true);
      })
    }
    function unFavoriteGame(item){
      firestore.unFavoriteGame(item.portalCode, auth.currentUser.uid, (obj) => {
          setRefresh(true);
      })
    }

    function loadGame(game){
        history.push("/game/" + game.portalCode);
    }

    function convertGameModeEnumToString(type){
      if (type == 0){
        return "Team Death Match";
      }else if (type == 1){
        return "Free For All";
      }else if (type == 2){
          return "Conquest Large";
      }else if (type == 3){
            return "Conquest Small";
      }else if (type == 4){
              return "Rush";
      }
    }

  return (
    <div>
    {props.userData && (
        <div>
        <Container style={{paddingTop: '16px'}}>
              <Row>
              {noGames && (
                <Col className="mb-5" xs={12} md={6} lg={6}>
                    <Card style={{backgroundColor: "#000000", display: 'flex', alignContent: 'center', alignItems: 'center', flex: '1'}}>
                          <div style={{position: 'absolute', display: 'flex', alignItems: 'center', alignContent: 'center', right: '16px', top: '16px'}}>


                          </div>
                        <CardBody style={{backgroundColor:"#1d253b", width: '100%'}}>


                        <div style={{display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'center'}}>

                <Row>
                                                          <CardTitle tag="h5" style={{textAlign: 'center', width: '100%', marginLeft: '8px', marginRight: '8px'}} >No Games Found.</CardTitle>
                                                          </Row>
                                                          <div style={{flexBasis: '100%', height: '0'}}/>
                        <Button  onClick={() => {
                          history.push("/add-game");

                        }}>Add one now!</Button>
                        </div>
                        </CardBody>
                    </Card>
                </Col>
              )}
              {games && games.map((item) => {
                if (item != null){
                  return (
                    <Col className="mb-5" xs={12} md={6} lg={6} key={item.portalCode}>
                        <Card style={{backgroundColor: "#000000", display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                              <CardImg top width="100%" src={item.banner} style={{opacity: '0.4'}} alt="Card image cap"/>
                              <div style={{position: 'absolute', display: 'flex', alignItems: 'center', alignContent: 'center', right: '16px', top: '16px'}}>
                              <div style={{marginRight: '16px'}}>
                              {item.favorites.length == 0 || item.favorites.length > 1 && (
                                <CardText tag="h4" className="modernista" style={{color: "#FF6C00"}}>{item.favorites.length}</CardText>
                              )}
                              {item.favorites.length == 1 && (
                                <CardText  tag="h4" className="modernista" style={{color: "#FF6C00"}}>{item.favorites.length}</CardText>
                              )}
                              {!item.favorites && (
                                  <CardText tag="h4" className="modernista" style={{color: "#FF6C00"}}>0</CardText>
                              )}
                              </div>

                              {item.favorites && item.favorites.includes(auth.currentUser.uid) && (
                                <img onClick={() => unFavoriteGame(item)} style={{height: '30px', width: '30px'}} src={heart}/>
                              )}
                              {!item.favorites.includes(auth.currentUser.uid) && (
                                <img onClick={() => favoriteGame(item)} style={{height: '30px', width: '30px'}} src={heartOutline}/>
                              )}

                              </div>
                            <CardBody style={{backgroundColor:"#1d253b", width: '100%'}}>


                            <div style={{display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'center'}}>

<Row>
                                                              <CardTitle tag="h5" style={{textAlign: 'center', width: '100%', marginLeft: '8px', marginRight: '8px'}} >{item.title}</CardTitle>
                                                              </Row>
                                                              <div style={{flexBasis: '100%', height: '0'}}/>
                            <Button  onClick={() => loadGame(item)}>Details</Button>
                            </div>
                            </CardBody>
                        </Card>
                    </Col>
                  )
                }
              })}
              </Row>
          </Container>
          </div>
    )}
    </div>
  );
}
