/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import classnames from "classnames";
import {useHistory, Link} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {useFirestore, auth} from "FirebaseInstance.js"
import {onAuthStateChanged} from "firebase/auth";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

import {Helmet} from "react-helmet";
import ogImage from "assets/img/og_image.jpg";

// core components
import AccountNavbar from "components/Navbars/AccountNavbar.js";
import Footer from "components/Footer/Footer.js";
import ProfileGamesBlock from "views/IndexSections/ProfileGamesBlock.js"

let ps = null;

export default function AccountPage(props) {
  const history = useHistory();
  const firestore = useFirestore();
  const [tabs, setTabs] = React.useState(1);
  const [userData, setUserData] = React.useState(null);
  const [displayName, setDisplayName] = React.useState("");

  function changeShowcasedAward(award){
    firestore.changeMyShowcasedAward(award, (update) => {
      firestore.getMyUserData((result) => {
        if (result != null){
          setUserData(result);
        }else{
          history.push("/");
        }
      })
    });
  }

  function validateDisplayName(){
    if (displayName != userData.displayName){
      firestore.checkIfDisplayNameAvailable(displayName, (result) => {
        if (result == true){
          //lets go ahead and create their account from anonymous.
          firestore.updateDisplayName(displayName, (result) => {
            if (result){
              requestUserData()
              alert("Name successfully changed.")
            }else{
              alert("Something happened, please try again later.")
            }
          })

        }else{
          alert('Display name is unavailable! Please try another.')
        }
    }
  )
    }
    }

    function updateUserData(data){
      setUserData(data)
      setDisplayName(data.displayName)
    }

    function requestUserData(){
      firestore.getMyUserData((result) => {
        if (result != null){
          updateUserData(result);
        }else{
          history.push("/");
        }
      })
    }



  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
      console.log("checker");

    }

    onAuthStateChanged(auth, (user) => {
      console.log('getting user data');
      if (user){
        //we're alive!
          requestUserData()

      }else{
        //make us live!
        history.push("/");
      }
    })

    if (auth.currentUser != null && userData == null){
      console.log('getting user data');
        requestUserData()
    }

    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  },[]);
  return (
    <>
      <AccountNavbar userData={userData} />

      <Helmet>
          <title>{"BF Portal Pro"}</title>
          <meta name="title" content={"BF Portal Pro"} />
          <meta name="og:title" content={"BF Portal Pro"} />
          <meta name="twitter:title" content={"BF Portal Pro"} />
          <meta name="description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:description" content={"Play hundreds of new game modes on BF Portal Pro today!!"} />
          <meta name="twitter:description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:image" content={ogImage} />
          <meta name="twitter:image" content={ogImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bfportalpro" />
          <meta name="twitter:creator" content="@bfportalpro" />
      </Helmet>
      {userData && (

        <div className="wrapper">
          <div style={{paddingTop: '20vh'}}>
            <Container className="align-items-center" style={{overflow:'hidden'}}>
              <h2 className="modernista  mt-5">Display Name</h2>
                <FormGroup style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                  <Input defaultValue={displayName} onChange={e => setDisplayName(e.target.value)}  placeholder="xXBF_PORTAL_PROXx" type="text" />
                  <Button onClick={() => {
                    validateDisplayName();
                  }} color="danger">Save</Button>
                </FormGroup>
                <h2 className="modernista  mt-5">Showcased Award</h2>


                <Row>
                {userData && userData.awards && userData.awards.map((item) => {
                  if (item != null){
                    return (
                      <Col xs={12} md={6} lg={2} key={item.name}>

                      <Card style={{borderColor: "#FF6C00", backgroundColor:"#000000", borderWidth: '2px', borderStyle: 'solid'}}>
                      <CardImg top width="100%" src={item.icon} style={{opacity: '0.4'}} alt="Card image cap"/>
                            <CardBody style={{backgroundColor:"#222a42"}}>
                                <CardTitle className="modernista" tag="h5">{item.name}</CardTitle>

                                            <CardText>{item.description}</CardText>
                            </CardBody>
                            <CardBody style={{backgroundColor:"#1d253b"}}>


                            <div style={{display: 'flex', justifyContent: 'center'}}>
                            {userData.highlightedAward.name != item.name && (
                              <Button style={{paddingLeft: '8px', paddingRight: '8px'}}  onClick={() => {changeShowcasedAward(item)}}>Showcase</Button>
                            )}
                            {userData.highlightedAward.name == item.name && (
                              <Button  color="primary" style={{paddingLeft: '8px', paddingRight: '8px'}} onClick={() => {changeShowcasedAward(item)}}>Showcased!</Button>
                            )}
                            </div>
                            </CardBody>
                      </Card>
                          </Col>
                    )
                  }})}
                  </Row>
            </Container>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
