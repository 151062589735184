/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import classnames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
import {useFirestore} from "FirebaseInstance.js"

import {
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Label,
  FormGroup,
  Form,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";
export default function MapSelectionBlock(props) {
var firestore = useFirestore();
  const [inputFocus, setInputFocus] = React.useState(false);
    const numberOfCards = 11;
    const [searchString, setSearchString] = React.useState("");
    const [games, setGames] = React.useState([]);
    const [selectedMaps, setSelectedMaps] = React.useState([]);

    useEffect(() => {
      setSelectedMaps([]);
      props.game.maps.forEach(element => {
        firestore.getMapFromDocument(element, (result) => {
        setSelectedMaps(selectedMaps => [...selectedMaps, result]);
        })
      });
    }, []);

  return (
    <div className="section section-basic" id="basic-elements">
      <Container>
            <Row xs={1} lg={3}>
            {selectedMaps && selectedMaps.map((item) => {
                  return (
                    <Col key={item.mapId} selected={selectedMaps}>
                          <Card>
                                <CardImg top width="100%" src={item.mapBanner} alt="Card image cap"/>
                              <CardBody>
                                  <CardTitle tag="h5">{item.title}</CardTitle>
                                  <CardSubtitle tag="h6" className="mb-2 text-muted">Size: {item.mapSize}</CardSubtitle>
                              </CardBody>
                          </Card>
                    </Col>
                  )
            })}
            </Row>
        </Container>
    </div>
  );
}
