/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import classnames from "classnames";
import {useHistory, Link} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {useFirestore, auth} from "FirebaseInstance.js"
import {onAuthStateChanged} from "firebase/auth";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

import {Helmet} from "react-helmet";
import ogImage from "assets/img/og_image.jpg";
// core components
import AccountNavbar from "components/Navbars/AccountNavbar.js";
import Footer from "components/Footer/Footer.js";
import ProfileGamesBlock from "views/IndexSections/ProfileGamesBlock.js"

export default function AccountPage(props) {
  const history = useHistory();
  const firestore = useFirestore();
  const [tabs, setTabs] = React.useState(1);
  const [userData, setUserData] = React.useState(null);
  let ps = null;

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
      console.log("checker");


    }

    onAuthStateChanged(auth, (user) => {
      console.log('getting user data');
      if (user){
        //we're alive!
        firestore.getMyUserData((result) => {
          if (result != null){
            setUserData(result);
          }else{
            history.push("/");
          }
        })
      }else{
        //make us live!
        history.push("/");
      }
    })

    if (auth.currentUser != null){
      console.log('getting user data');
        firestore.getMyUserData((result) => {
          if (result != null){
            setUserData(result);
          }else{
            history.push("/");
          }
        })
    }
    
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  },[]);
  return (
    <>
      <AccountNavbar userData={userData} />
      <Helmet>
          <title>{"BF Portal Pro"}</title>
          <meta name="title" content={"BF Portal Pro"} />
          <meta name="og:title" content={"BF Portal Pro"} />
          <meta name="twitter:title" content={"BF Portal Pro"} />
          <meta name="description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:description" content={"Play hundreds of new game modes on BF Portal Pro today!!"} />
          <meta name="twitter:description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
          <meta name="og:image" content={ogImage} />
          <meta name="twitter:image" content={ogImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@bfportalpro" />
          <meta name="twitter:creator" content="@bfportalpro" />
      </Helmet>
      {userData && (

        <div className="wrapper">
          <div style={{paddingTop: '20vh'}}>
            <Container className="align-items-center" style={{overflow:'hidden'}}>
              <Row>
                <Col lg="6" md="6">
                  <ProfileGamesBlock userData={userData} />
                </Col>
                <Col className="ml-auto mr-auto" style={{paddingTop: '10vh'}} lg="4" md="6">
                  <Card className="card-coin card-plain" style={{backgroundColor: "#000000"}}>
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={userData.avatar}
                      />
                      <h2 className="title">{userData.displayName}</h2>
                      <Link to="/edit"><h4 className="title mt-0" style={{color: "#ff6c00"}}>Edit Profile</h4></Link>
                    </CardHeader>
                    <CardBody>
                      <Nav
                        className="nav-tabs-primary justify-content-center"
                        tabs
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: tabs === 1,
                            })}
                            onClick={(e) => {
                              e.preventDefault();
                              setTabs(1);
                            }}
                          >
                            Stats
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-subcategories"
                        activeTab={"tab" + tabs}
                      >
                        <TabPane tabId="tab1" style={{overflow:'hidden'}}>
                          <Table className="tablesorter" responsive style={{overflow:'hidden'}}>
                            <tbody>
                              <tr>
                                <td>Games:</td>
                                <td>{userData.games.length}</td>
                              </tr>
                              <tr>
                                <td>Favorites:</td>
                                <td>{userData.favorites.length}</td>
                              </tr>
                              <tr>
                                <td>Pro Points</td>
                                <td>{userData.proPoints}</td>
                              </tr>
                              <tr>
                                <td>Joined</td>
                                <td>{userData.createdDate.toDate().toLocaleDateString("en-US")}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </TabPane>
                      </TabContent>
                      <Container style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                      <h5 className="title modernista">Showcased Award</h5>
                      <img style={{height: '60px', width: '60px'}} src={userData.highlightedAward.icon}/>
                      <h4 className="title modernista" style={{marginTop: '16px'}}>{userData.highlightedAward.name}</h4>
                      <span style={{marginTop: '0px'}}>{userData.highlightedAward.description}</span>
                      </Container>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
