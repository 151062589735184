/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";
import classnames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
import { useHistory } from "react-router-dom";
import MapDisplayBlock from "views/IndexSections/MapDisplayBlock.js"
import {useFirestore, getCurrentUser, auth} from "FirebaseInstance.js"
import heart from "assets/img/heart.png";
import heartOutline from "assets/img/heart_outline.png";
import award from "assets/img/award2.png";
import {
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Label,
  FormGroup,
  Form,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";
import {Helmet} from "react-helmet";
export default function GamesBlock(props) {
var firestore = useFirestore();

function convertGameModeEnumToString(type){
  if (type == 0){
    return "Team Death Match";
  }else if (type == 1){
    return "Free For All";
  }else if (type == 2){
      return "Conquest Large";
  }else if (type == 3){
        return "Conquest Small";
  }else if (type == 4){
          return "Rush";
  }
}

const history = useHistory();
  const [inputFocus, setInputFocus] = React.useState(false);
    const numberOfCards = 11;
    const [searchString, setSearchString] = React.useState("");
    const [game, setGame] = React.useState(null);
    const [faves, setFaves] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);


    useEffect(() => {
      if (props.portalCode != null){
        firestore.getGameById(props.portalCode, (result) => {
          if (result != null){
            setGame(result)
          }else{
            history.push("/");
          }
        });
      }else{
          history.push("/");
      }
          setRefresh(false);
    }, [searchString, refresh]);

    function favoriteGame(item){
      firestore.favoriteGame(item.portalCode, auth.currentUser.uid, (obj) => {
            setRefresh(true);
      })
    }
    function unFavoriteGame(item){
      firestore.unFavoriteGame(item.portalCode, auth.currentUser.uid, (obj) => {
          setRefresh(true);
      })
    }

  return (
    <div className="section section-basic" id="basic-elements">
    {game && auth && auth.currentUser && (
    <Container className="mt-5">

        <Helmet>
            <title>{game.title + " | BF Portal Pro"}</title>
            <meta name="title" content={game.title + " | BF Portal Pro"} />
            <meta name="description" content={"Play " + game.title + " on BF Portal Pro today! " + game.description} />
            <meta name="og:title" content={game.title} />
            <meta name="twitter:title" content={game.title} />
            <meta name="og:description" content={"Play " + game.title + " on BF Portal Pro today! " + game.description} />
            <meta name="twitter:description" content={"Play " + game.title + " on BF Portal Pro today! " + game.description} />
            <meta name="og:image" content={game.banner} />
            <meta name="twitter:image" content={game.banner} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@bfportalpro" />
            <meta name="twitter:creator" content="@bfportalpro" />
        </Helmet>
    <Container style={{height: '40vmin', display: 'flex', justifyContent:'center', alignContent: 'center', alignItems: 'center', padding: '0', position:'relative', backgroundImage: 'url(' + game.banner + ")", backgroundSize: 'cover'}}>
    {game.editorsChoice && (
      <img style={{borderColor: "#FF6C00", backgroundColor:"#000000", borderWidth: '4px', borderStyle: 'solid', position: 'absolute', opacity:'0.4', height: '100%', width: '100%'}}/>
    )}
    {!game.editorsChoice && (
      <img style={{backgroundColor: "#000000", position: 'absolute', opacity:'0.4', height: '100%', width: '100%'}}/>
    )}
      <h2 className="mt-5 modernista" style={{color: "#ffffff", zIndex: '5'}}>{game.title}</h2>

      {game.editorsChoice && (
        <div style={{display: 'flex', position:'absolute', right: '24px', top: '8px', height: '64px', alignContent: 'center', alignItems: 'center'}}>
          <span className="modernista" style={{color: "#FF6C00", marginRight: '16px', zIndex: '5', fontSize: '18px'}}>Editor's Choice</span>
        <img style={{height: '32px', width: '32px'}} src={award}/>
        </div>
      )}
    </Container>
        <h3 className="category mt-5">Description</h3>
        <h3 style={{whiteSpace:'pre-line'}}>{game.description}</h3>
        <Row>
        <Col>
            <h3 className="category mt-5">Player Count</h3>
        <h4>{game.playerCount} Players</h4>
            <h3 className="category mt-5">Base Game Mode</h3>
        <h4>{convertGameModeEnumToString(game.gameMode)}</h4>
            <h3 className="category mt-5">Custom Logic</h3>
            {game.customLogic && (
              <h4>Yes</h4>
            )}
            {!game.customLogic && (
              <h4>No</h4>
            )}
                <h3 className="category mt-5">Portal Code</h3>
            <Button  onClick={() => {

               /* Copy the text inside the text field */
              navigator.clipboard.writeText(game.portalCode.toUpperCase());

              /* Alert the copied text */
              alert("Copied Portal Code: " + game.portalCode.toUpperCase());
            }}>{game.portalCode.toUpperCase()}</Button>
            </Col>
            <Col>
                <h3 className="category mt-5">Favorites</h3>
            {game.favorites.length == 0 || game.favorites.length > 1 && (
              <h4>{game.favorites.length} Favorites</h4>
            )}
            {game.favorites.length == 1 && (
              <h4>{game.favorites.length} Favorite</h4>
            )}

            {game.favorites && game.favorites.includes(auth.currentUser.uid) && (
              <img onClick={() => unFavoriteGame(game)} style={{height: '30px', width: '30px'}} src={heart}/>
            )}
            {!game.favorites.includes(auth.currentUser.uid) && (
              <img onClick={() => favoriteGame(game)} style={{height: '30px', width: '30px'}} src={heartOutline}/>
            )}

                <h3 className="category mt-5">Date Submitted</h3>
            <h4>{game.submittedDate.toDate().toLocaleDateString("en-US")}</h4>
            {game.creatorName && game.creatorName !== "" && (
              <div>
              <h3 className="category mt-5">Creator</h3>
          <h4>{game.creatorName}</h4>
          </div>
            )}
            {game.editorLink && (
              <div>
              <h3 className="category mt-5">Editor Link</h3>
              <h4 style={{color: "#FF6C00"}} onClick={() => window.open(game.editorLink, "_blank")}>{game.editorLink}</h4>
              </div>
            )}
            </Col>
            </Row>
                <h3 className="category mt-5">Maps</h3>
            <MapDisplayBlock game={game}/>
      </Container>
    )}
      <Container id="games">
            <Row xs={3}>
            </Row>
        </Container>
    </div>
  );
}
