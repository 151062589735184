/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {auth} from "FirebaseInstance.js"
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/PageHeader/PageHeader.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import Basics from "views/IndexSections/Basics.js";
import GamesBlock from "views/IndexSections/GamesBlock.js"
import Navbars from "views/IndexSections/Navbars.js";
import Tabs from "views/IndexSections/Tabs.js";
import Pagination from "views/IndexSections/Pagination.js";
import Notifications from "views/IndexSections/Notifications.js";
import Typography from "views/IndexSections/Typography.js";
import JavaScript from "views/IndexSections/JavaScript.js";
import NucleoIcons from "views/IndexSections/NucleoIcons.js";
import Signup from "views/IndexSections/Signup.js";
import Examples from "views/IndexSections/Examples.js";
import Download from "views/IndexSections/Download.js";

import {Helmet} from "react-helmet";
import ogImage from "assets/img/og_image.jpg";

export default function Index(props) {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    document.body.classList.toggle("index-page");

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
      } else {
        // User is signed out
        // ...
      }
    });
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  },[]);
  return (
    <>
      <IndexNavbar/>

        <Helmet>
            <title>{"BF Portal Pro"}</title>
            <meta name="title" content={"BF Portal Pro"} />
            <meta name="og:title" content={"BF Portal Pro"} />
            <meta name="twitter:title" content={"BF Portal Pro"} />
            <meta name="description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
            <meta name="og:description" content={"Play hundreds of new game modes on BF Portal Pro today!!"} />
            <meta name="twitter:description" content={"Play hundreds of new game modes on BF Portal Pro today!"} />
            <meta name="og:image" content={ogImage} />
            <meta name="twitter:image" content={ogImage} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@bfportalpro" />
            <meta name="twitter:creator" content="@bfportalpro" />
        </Helmet>
      <div className="wrapper">
      {user && (
        <div>
          <PageHeader />
          <div className="main">
          <GamesBlock />
          </div>
          </div>
      )}
        <Footer />
      </div>
    </>
  );
}
